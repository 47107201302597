import moment from 'moment'

// 10位时间戳转换
export function formatDate (value) {
  let date = new Date(parseInt(value) * 1000);
  let y = date.getFullYear();
  let m = date.getMonth() + 1;
    m = m < 10 ? "0" + m : m;
  let d = date.getDate();
    d = d < 10 ? "0" + d : d;
  let h = date.getHours();
    h = h < 10 ? "0" + h : h;
  let minute = date.getMinutes();
  let second = date.getSeconds();
    minute = minute < 10 ? "0" + minute : minute;
    second = second < 10 ? "0" + second : second;
  return y +'.'+ m + "." + d ;
};
export function formatDate2 (value) {
  let date = new Date(parseInt(value) * 1000);
  let y = date.getFullYear();
  let m = date.getMonth() + 1;
    m = m < 10 ? "0" + m : m;
  
  return y +'.'+ m;
};
export function formatDate3 (value) {
  let date = new Date(parseInt(value) * 1000);
  // let y = date.getFullYear();
  let m = date.getMonth() + 1;
    m = m < 10 ? "0" + m : m;
  let d = date.getDate();
    d = d < 10 ? "0" + d : d;
  return m +'.'+ d;
};
export function formatDateYear (value) {
  let date = new Date(parseInt(value) * 1000);
  let y = date.getFullYear();
  return y+'年';
};
export function formatDateMonth (value) {
  let date = new Date(parseInt(value) * 1000);
  let m = date.getMonth() + 1;
    m = m < 10 ? "0" + m : m;
  return m+'月';
};
export function formatDateDay (value) {
  let date = new Date(parseInt(value) * 1000);
  let d = date.getDate();
    d = d < 10 ? "0" + d : d;
  return d;
};

// 13位时间戳转换
export function fmtDate(date){
  return date?moment(date).format('YYYY.MM.DD'):'';
}
export function fmtDate1(date){
  return date?moment(date).format('YYYY-MM-DD'):'';
}
export function fmtDate2(date){
  return date?moment(date).format('YYYY/MM/DD'):'';
}
export function fmtYear(date){
  return date?moment(date).format('YYYY'):'';
}
export function fmtMonth(date){
  return date?moment(date).format('MM'):'';
}
export function fmtDay(date){
  return date?moment(date).format('DD'):'';
}
