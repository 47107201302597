import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import setTitle from './plugins/setTitle'
// Vue.use(setTitle, { title: '融创服务控股有限公司' })
// import { createRouter, createWebHistory } from 'vue-router'
// import VueMeta from 'vue-meta'
// Vue.use(VueMeta)

import "/public/static/css/lg.css";
import "/public/static/css/style.css";
// import "/public/static/css/phone.css";

// 引入axios
import axios from 'axios'
Vue.prototype.axios =axios;
axios.defaults.baseURL = "/api"
axios.defaults.headers.post['Content-Type'] = "application/json"

// 引入element-ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

import * as filters from './filters'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

import MetaInfo from 'vue-meta-info';
Vue.use(MetaInfo)

// 引入 meta.js 文件
// import meta from './filters/meta.js';

// Vue.config.productionTip = false
// // 页面跳转后滚动条位于最上方
// router.afterEach((to, from, next) => {
//   window.scrollTo(0, 0);
// });

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
