import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  { 
    path:'/',
    redirect:'/home',
  },
  {
    path: '/home',
    name: 'home',
    meta:{
      title:'首页',
      isLogin:false,
    },
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    meta:{
      title:'关于我们',
      isLogin:false,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/news',
    name: 'news',
    meta:{
      title:'新闻中心',
      isLogin:false,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/NewsView.vue')
  },
  {
    path: '/newsDetails',
    name: 'newsDetails',
    meta:{
      title:'新闻中心',
      isLogin:false,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/NewsDetails.vue')
  },
  {
    path: '/property',
    name: 'property',
    meta:{
      title:'物业服务',
      isLogin:false,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/PropertyView.vue')
  },
  {
    path: '/business',
    name: 'business',
    meta:{
      title:'商业运营',
      isLogin:false,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/BusinessView.vue')
  },
  {
    path: '/enable',
    name: 'enable',
    meta:{
      title:'科技赋能',
      isLogin:false,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/EnableView.vue')
  },
  {
    path: '/responsibility',
    name: 'responsibility',
    meta:{
      title:'社会责任',
      isLogin:false,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ResponsibilityView.vue')
  },
  {
    path: '/relationship',
    name: 'relationship',
    meta:{
      title:'投资者关系',
      isLogin:false,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/RelationshipView.vue')
  },
  {
    path: '/join',
    name: 'join',
    meta:{
      title:'加入我们',
      isLogin:false,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/JoinView.vue')
  },
  {
    path: '/joinDetails',
    name: 'joinDetails',
    meta:{
      title:'加入我们',
      isLogin:false,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/JoinDetails.vue')
  },
  {
    path: '/JoinBd',
    name: 'JoinBd',
    meta:{
      title:'加入我们',
      isLogin:false,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/JoinBd.vue')
  },
  {
    path: '/follow',
    name: 'follow',
    meta:{
      title:'联络关注',
      isLogin:false,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/FollowView.vue')
  },
  {
    path: '/search',
    name: 'search',
    meta:{
      title:'搜索结果',
      isLogin:false,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchView.vue')
  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
