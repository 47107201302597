<template>
  <div class="social">
    <div class="box_con">
      <div class="box_title">
        <h3>{{ info.title }}</h3>
      </div>
      <div class="items">
        <swiper :options="swiperOptionSocial" ref="mySwiper">
          <swiper-slide v-for="(items,index) in list" :key="index">
            <div class="item">
              <div class="pic"><img :src="items.thumb" alt=""></div>
              <div class="tct">
                <p class="">
                  {{ items.description }}
                </p>
                
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-pagination-social" slot="pagination"></div>
        </swiper>
        <em class="more">
          <router-link to="/responsibility?id=55">查看更多 <img src="/static/images/more_icon.png" alt=""></router-link>
        </em>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";

export default {
  name: "Social",
  components: {
    swiper,
    swiperSlide
  },
  data: () => ({
    swiperOptionSocial: {
      autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: false
      },
      // 显示分页
      pagination: {
        el: ".swiper-pagination-social",
        clickable: true //允许分页点击跳转
      },
    },
    info:{},
    list: {},
  }),
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  },
  methods: {
    getData() {
      // 菜单
      Axios.get("/Homeze")
        .then((res) => {
          if (res.data.code == 200) {
            this.info = res.data.data;
            this.list = res.data.data.article;
          }
          else {
            alert(res.data.data)
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
  },
  created() {
    this.id = this.$route.query.id;
    this.getData();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
