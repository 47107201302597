<template>
  <div class="banner">
    <el-carousel trigger="click" :interval="3000" arrow="always">
      <el-carousel-item v-for="items in banner" :key="items.id">
        <img :src="items.image" alt="">
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import Axios from "axios";

export default {
  name: "Banner",
  data: () => ({
    banner: {},
  }),
  computed: {
  },
  methods: {
    getData() {
      // 菜单
      Axios.get("/Picbanner")
        .then((res) => {
          if (res.data.code == 200) {
            this.banner = res.data.data;
          }
          else {
            alert(res.data.data)
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
  },
  created() {
    this.id = this.$route.query.id;
    this.getData();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
