<template>
  <div class="service">
      <div class="box_con">
        <div class="items box">
          <div class="item"
          @mouseover="active = index"
          :class="{ active: index === active }"
          v-for="(items,index) in homeservice" 
          :key="items.id">
            <div class="pic">
              <img :src="items.image" :alt="items.title"/>
            </div>
            <router-link :to="'/property?id='+items.id">
              <div class="tct">
                <h3>{{ items.title }}</h3>
                <p class="entitle">{{ items.entitle }}</p>
            </div>
          </router-link>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import Axios from "axios";

export default {
  name: "Service",
  data: () => ({
    active: 0,
    homeservice: {},
  }),
  computed: {
  },
  methods: {
    getData() {
      // 菜单
      Axios.get("/Homeservice")
        .then((res) => {
          if (res.data.code == 200) {
            this.homeservice = res.data.data;
          }
          else {
            alert(res.data.data)
          }
        })
        .catch((error) => {
          console.log(error);
        });  
    },
  },
  mounted() {
  },
  created() {
    this.id = this.$route.query.id;
    this.getData();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.active .entitle {
  width: 15.333333vw
}
</style>
