<template>
  <div class="lg_wrap" :key="$route.fullPath">

    <!-- banner -->
    <Banner name="Banner"></Banner>

    <!-- service -->
    <Service name="Service"></Service>

    <!-- news -->
    <News name="News"></News>

    <!-- social -->
    <Social name="Social"></Social>
    
  </div>
</template>

<script>
import Banner from '@/components/home/Banner.vue'
import Service from '@/components/home/Service.vue'
import News from '@/components/home/News.vue'
import Social from '@/components/home/Social.vue'

export default {
  name: 'HomeView',
  components: {
    Banner,
    Service,
    News,
    Social,
  },
}
</script>
