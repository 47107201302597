<template>
  <div class="news">
    <div class="box_con">
      <div class="box_title">
        <h3>{{ info.title }}</h3>
      </div>
      <div class="box news_con">
        <div class="news_left">
          <div class="news_item">
            <swiper :options="swiperOption" ref="mySwiper">
              <swiper-slide v-for="items in newsTop" :key="items.id">
                <div class="item box">
                  <div class="item_left">
                    <span>{{ items.publish_time | formatDateDay }}</span>
                    <span>{{ items.publish_time | formatDate2 }}</span>
                  </div>
                  <div class="item_right">
                    <router-link :to="'/newsDetails?id=' + items.category_id + '&aid=' + items.id">
                      <h3 class="mult2">{{ items.title }}</h3>
                    </router-link>
                    <p class="mult2">{{ items.description }}</p>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
            <div class="swiper-pagination-news" slot="pagination"></div>
          </div>
          <div class="news_list">
            <div class="item box" v-for="(items,index) in newsList" :key="items.id" v-if="index < 3">
              <div class="item_left">
                <span>{{ items.publish_time | formatDate }}</span>
              </div>
              <div class="item_right">
                <router-link :to="'/newsDetails?id=' + items.category_id + '&aid=' + items.id">
                  <p class="mult1">{{ items.title }}</p>
                </router-link>
              </div>
            </div>
            <em class="more">
              <router-link to="/news?id=14">查看更多 <img src="/static/images/more_icon.png" alt="">
                </router-link>
              </em>
          </div>
        </div>
        <div class="news_right">
          <swiper :options="swiperOption" ref="mySwiper">
            <swiper-slide v-for="items in newsTop" :key="items.id">
              <div class="pic">
                <router-link :to="'/newsDetails?id=' + items.category_id + '&aid=' + items.id">
                  <img :src="items.home_thumb" :alt="items.title">
                </router-link>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";

export default {
  name: "News",
  components: {
    swiper,
    swiperSlide
  },
  data: () => ({
    swiperOption: {
      simulateTouch : false,
      autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: false
      },
      // 显示分页
      pagination: {
        el: ".swiper-pagination-news",
        clickable: true //允许分页点击跳转
      },
      // 设置点击箭头
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      }
    },
    info:{},
    newsTop: {},
    newsList: [],//分页数据
  }),
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  },
  methods: {
    getData() {
      // 菜单
      Axios.get("/Homenews")
        .then((res) => {
          if (res.data.code == 200) {
            this.info = res.data.data.cate
            this.newsList = res.data.data.list;
            this.newsTop = res.data.data.top
          }
          else {
            alert(res.data.data)
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
  },
  created() {
    this.id = this.$route.query.id;
    this.getData();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
